<template>
  <v-main>
    <router-view />
  </v-main>
</template>

<script>
  export default {
    created () {
    },
    components: {
    },
    computed: {
      programId () {
        return this.$store.getters.programId
      },
    },
    watch: {
      $route: function () {
        this.$nextTick(() => this.$vuetify.goTo(0))
      }
    }
  }
</script>
